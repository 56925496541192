<template>
<div class="animated fadeIn">
    <h3 class="mb-4">{{translate('customer_orders')}}</h3>
    <q-tabs no-caps class="no-controls shadow-2 bg-light" align="left" v-model="tab" dense>
        <q-tab @click.prevent="getOrders('individual')" :ripple="false" name="individual" :label="translate('individual_orders')" />
        <q-tab @click.prevent="getOrders('group')" :ripple="false" name="group" :label="translate('group_orders')" />
    </q-tabs>
    <div class="list-group mb-3">
        <b-list-group-item id="salesListHeader" class="flex-column align-items-start vertical-margin mobile-hidden">
            <div class="d-flex w-100 justify-content-between align-items-center">
                <b-row class="align-items-center">
                    <b-col md="3">
                        <small class="text-muted display-block">{{translate('date')}}</small>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{translate('type')}}</small>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{translate('sale_amount')}}</small>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{translate('payment_status')}}</small>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{translate('buyer')}}</small>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{ translate('add_payment') }}</small>
                    </b-col>
                    <b-col md="" class="text-center">
                        <small class="text-muted display-block">{{ translate('heading_credits_history') }}</small>
                    </b-col>
                </b-row>
            </div>
        </b-list-group-item>
        <b-list-group-item v-for="(sale,i) in sales" :key="'salew-' + i + '-' + (i.id, sale.id)" :id="sale.id" href="#" class="list_wrapper-customer-sales flex-column align-items-start vertical-margin p-0">
            <b-row :class="$q.screen.lt.sm ? 'm-0 pl-0 pr-0 pt-3 pb-3' : 'p-2'" @click="showSaleDetailsPanel(sale.id)" class="align-items-center">
                <b-col md="3">
                    <small class="text-muted display-block mobile-visible">{{translate('date')}}</small>
                    <strong>{{ prettyDate(sale.sale_date) }}</strong>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <small class="text-muted display-block">{{ translate('sales_type_' + sale.sale_type) }}</small>
                    <strong>{{ sale.purchased_item_name }}</strong>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <small class="text-muted display-block mobile-visible">{{translate('sale_amount')}}</small>
                    <strong>{{ sale.amount }}<template v-if="currency"> {{ currency }}</template></strong>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <small class="text-muted display-block mobile-visible">{{translate('payment_status')}}</small>
                    <strong>{{ translate('sales_payment_status_' + sale.payment_status) }}</strong>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <small class="text-muted display-block">{{ translate(sale.buyer_type) }}</small>
                    <strong>{{  sale.buyer_name }}</strong>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <template v-if="saleCanBePaid(sale)">
                        <q-btn v-if="!$q.screen.lt.sm" @click.stop.prevent="showAddPaymentPanel(sale.id, sale)" round dense color="primary">
                            <q-icon name="fa fa-credit-card" size="1.25rem" />
                        </q-btn>
                        <q-btn v-if="$q.screen.lt.sm" class="mt-2 full-width" icon="fa fa-credit-card" @click.stop.prevent="showAddPaymentPanel(sale.id, sale)" color="primary" no-caps :label="translate('add_payment')" />
                    </template>
                </b-col>
                <b-col md="" class="text-center text-xs-left">
                    <q-btn v-if="!$q.screen.lt.sm" @click.stop.prevent="showCreditsHistoryPanel(sale.id)" round dense color="dark">
                        <q-icon name="fas fa-balance-scale" size="1.25rem" />
                    </q-btn>
                    <q-btn v-if="$q.screen.lt.sm" class="mt-2 full-width" icon="fas fa-balance-scale" @click.stop.prevent="showCreditsHistoryPanel(sale.id)" color="dark" no-caps :label="translate('credits_history')" />
                </b-col>
            </b-row>
        </b-list-group-item>
        <infinite-loading :identifier="'inf-sales-'+group_id + '-' + customer_id + '-' +infSales" slot="append" @infinite="getSales" />
    </div>
</div>
</template>

<script>
import * as jwt_decode from 'jwt-decode'
import { eventBus } from '../../main';
import _ from 'lodash'
import Sale from './show/SaleDetailsCustomer'
import creditsHistory from './show/CreditsHistory'
import addPayment from './forms/AddSalePaymentCustomer'

export default {
    name: 'SalesCustomer',
    data: function () {
        return {
            currency: currency,
            tab: this.$route.params.tab_type === 'group' ? 'group' : 'individual',
            infSales: +new Date(),
            enableButon: false,
            sales: [],
            page: 1,
            state: {},
            group_id: 0,
        }
    },
    computed: {
        customer_id: function () {
            this.token = localStorage.getItem('access_token')
            var decoded = jwt_decode(this.token)
            var id = 0
            if (decoded.identity.customer_id) {
                id = decoded.identity.customer_id
            }
            return this.tab === 'individual' ? id : 0
        },
    },
    created: function () {
        this.getGroups()
    },
    mounted: function () {
        eventBus.$on('open_add_sale_payment_customer', (item) => {
            this.openAddSalePaymentPanel(item)
        })

        eventBus.$on('updateSaleStatus', (saleId, status) => {
            this.updateSalePaymentStatus(saleId, status)
        })
    },
    methods: {
        getOrders: function () {
            this.resetMainList()
        },
        getGroups: function ($state) {
            var groupUrl = baseUrl + 'groups?page=1&items_per_page=1000'

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(groupUrl, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        var group = response.data.items.filter(el => el.is_owner === true)
                        if (group.length > 0) {
                            this.group_id = group[0].id
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        updateSalePaymentStatus: function (id, status) {
            const index = this.sales.findIndex((obj => obj.id === id))
            this.$set(this.sales[index], 'payment_status', status)
        },
        resetMainList: function () {
            this.page = 1
            this.sales = []
            this.infSales++
        },
        getSales: function ($state) {
            var group_id = 0,
                customer_id = 0
            if (this.tab === 'group') {
                group_id = this.group_id
            }
            if (this.tab === 'individual') {
                customer_id = this.customer_id
            }

            var url = baseUrl + 'sales?page=' + this.page + '&items_per_page=' + this.$items_per_page +
            (customer_id ? '&customer_id=' + customer_id : '') +
            (group_id ? '&group_id=' + group_id : '')

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.sales.push(...response.data.items)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showSaleDetailsPanel(id) {
            const panelInstance = this.$showPanel({
                component: Sale,
                props: {
                    sale_id: id
                }
            })
        },
        showCreditsHistoryPanel(id) {
            const panelInstance = this.$showPanel({
                component: creditsHistory,
                props: {
                    sale_id: id
                }
            })
        },
        showAddPaymentPanel(id, sale) {
            const panelInstance = this.$showPanel({
                component: addPayment,
                props: {
                    sale_id: id,
                    sale: sale
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        openAddSalePaymentPanel: function (item) {
            const panelInstance = this.$showPanel({
                component: addPayment,
                props: {
                    sale_id: item.sale_id,
                    sale: item.sale
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        saleCanBePaid: function(sale) {
            return sale.creator_type == 'customer' && ['pending', 'online_payment_started'].includes(sale.payment_status)
        },
    }
}
</script>
