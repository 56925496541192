<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1">
                    <q-icon name="fas fa-shopping-cart mr-1" /> {{ translate('see_sale') }}
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item v-if="sale">
                    <span v-if="sale.created_at"><i class="text-muted fas fa-calendar mr-1"></i>{{ translate('sale_created_at') }}: {{ prettyDate(sale.created_at) }}</span>
                    <div class="full-width row m-0 text-muted align-items-center">
                        <template v-if="sale.customer_id">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center">
                                <q-avatar class="shadow-2" size="35px">
                                    <img v-if="customer_image_link" class="black-and-white" :src="customer_image_link">
                                    <i v-else class="fas fa-user fa-2x"></i>
                                </q-avatar>
                            </div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('customer') }}</small><br />
                                <strong>{{ sale.customer_name }}</strong>
                            </div>
                        </template>
                        <template v-if="sale.group_id">
                            <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-group fa-2x"></i></div>
                            <div class="col-10 col-md info-card">
                                <small>{{ translate('group') }}</small><br />
                                {{ sale.group_name }}
                            </div>
                        </template>
                        <div v-if="sale.payment_status" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-money fa-2x"></i></div>
                        <div v-if="sale.payment_status" class="col-10 col-md info-card">
                            <small>{{ translate('payment_status') }}</small><br />
                            <strong>{{ translate('sales_payment_status_' + sale.payment_status) }}</strong>
                        </div>
                    </div>
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-credit-card fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('sales_type_' + sale.sale_type) }}</small><br />
                            <strong>{{ sales_type_name }}</strong>
                        </div>
                        <div v-if="sale.credits" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tasks fa-2x"></i></div>
                        <div v-if="sale.credits" class="col-10 col-md info-card">
                            <small>{{ translate('credits') }}</small><br />
                            <strong>{{ sale.credits }}</strong>
                        </div>
                        <div v-if="sale.quantity" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                        <div v-if="sale.quantity" class="col-10 col-md info-card">
                            <small>{{ translate('quantity') }}</small><br />
                            <strong>{{ sale.quantity }}</strong>
                        </div>
                    </div>
                    <div v-if="sale && (sale.promo_code_id || sale.promo_standard_id)" class="full-width row m-0 text-muted align-items-center">
                        <div v-if="sale.promo_code_id" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-barcode fa-2x"></i></div>
                        <div v-if="sale.promo_code_id" class="col-10 col-md info-card">
                            <small>{{ translate('promo_code') }}</small><br />
                            <strong>{{ sale.promo_code_promotion_name }}</strong>
                        </div>
                        <div v-if="sale.promo_standard_id" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-tag fa-2x"></i></div>
                        <div v-if="sale.promo_standard_id" class="col-10 col-md info-card">
                            <small>{{ translate('standard_promo') }}</small><br />
                            <strong>{{ sale.standard_promotion_name }}</strong>
                        </div>
                    </div>
                    <div class="full-width row m-0 text-muted align-items-center">
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-desc fa-2x"></i></div>
                        <div class="col-10 col-md info-card">
                            <small>{{ translate('total_amount') }}</small><br />
                            <strong>{{ sale.amount }}<template v-if="currency"> {{ currency }}</template></strong>
                        </div>
                        <div class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-sort-amount-asc fa-2x"></i></div>
                        <div class="col-7 col-md info-card">
                            <small>{{ translate('payment_remaining_amount') }}</small><br />
                            <strong>{{ sale.amount - sale.paid }}<template v-if="currency"> {{ currency }}</template></strong>
                        </div>
                    </div>
                    <div v-if="sale && (sale.valid_from || sale.valid_until)" class="full-width row m-0 text-muted align-items-center">
                        <div v-if="sale.valid_from" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                        <div v-if="sale.valid_from" class="col-10 col-md info-card">
                            <small>{{ translate('valid_from') }}</small><br />
                            <strong>{{ prettyDate(sale.valid_from) }}</strong>
                        </div>
                        <div v-if="sale.valid_until" class="col-2 col-md-1 pl-0 pr-md-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                        <div v-if="sale.valid_until" class="col-10 col-md info-card">
                            <small>{{ translate('valid_until') }}</small><br />
                            <strong>{{ prettyDate(sale.valid_until) }}</strong>
                        </div>
                    </div>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'SaleDetailsCustomer',
    props: ['sale_id'],
    computed: {
        sales_type_name: function () {
            var name = this.sale.sale_type + '_name'
            return this.sale[name]
        },
    },
    data: function() {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
            customer_image_link: null,
            sale: {},
        };
    },
    created: function () {
        this.getCustomerSale()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getCustomerSale: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'sales/' + this.sale_id, {
                    headers: headers
                })
                .then(response => {
                    this.sale = response.data.item

                    if (response.data.item && response.data.item.customer_id) {
                        this.getCustomerProfilePicture(response.data.item.customer_id)
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getCustomerProfilePicture: function (customer_id) {
            var url = baseUrl + 'profile_images/link/customer/' + customer_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.customer_image_link = response.data.image_link ? baseUrl + response.data.image_link : null
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
