<template>
<div class="animated fadeIn">
    <sales-worker v-if="showSalesWorker" :baseUrl="baseUrl"></sales-worker>
    <sales-customer v-if="showSalesCustomer" :baseUrl="baseUrl"></sales-customer>
</div>
</template>

<script>
import _ from 'lodash'
import SalesWorker from './SalesWorker'
import SalesCustomer from './SalesCustomer';
export default {
    name: 'Sales',
    components: {
        'sales-worker': SalesWorker,
        'sales-customer': SalesCustomer
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            enableCard: false,
        }
    },
    computed: {
        showSalesWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showSalesCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
